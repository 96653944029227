import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify';
import { ThemeContext } from '../utils/themeContext';

export default function Contact() {
    const { theme, setTheme } = React.useContext(ThemeContext);
    const form = useRef();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: '',
        dataConsent: false
    });

    const updateInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value || e.target.checked
        });
    };

    const resetForm = (e) => {
        setFormData({
            ...formData,
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            subject: '',
            message: '',
            dataConsent: false
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_y4871bj', 'template_x2k464k', form.current, 'user_PDi1yLFd7mok0LuP2iCTL').then(
            (result) => {
                resetForm(e);

                toast.success(
                    <div className="text-center">
                        Mail trimis cu succes! <br />
                        Vă vom răspunde în cel mai scurt timp posibil.
                    </div>
                );
            },
            (error) => {
                toast.error(
                    <div className="text-center">
                        Eroare! <br />
                        Vă rugăm să încercați din nou.
                    </div>
                );
            }
        );
    };

    return (
        <div id="contact">
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                theme={theme === 'dark' ? 'dark' : 'light'}
                hideProgressBar={false}
                newestOnTop={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
            />

            <div className="container-padding space-y-10">
                <h1>Contact</h1>
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-10">
                    <a
                        href="mailto:nitageorgian@gmail.com"
                        className="block bg-bglight dark:bg-bgdark shadow px-4 py-2"
                    >
                        <div className="flex items-center space-x-2">
                            <span className="material-symbols-outlined text-theme1">mail</span>
                            <h6>Mail</h6>
                        </div>
                        <div>office@promptservice.ro</div>
                    </a>

                    <a href="tel:+40763983880" className="block bg-bglight dark:bg-bgdark shadow px-4 py-2">
                        <div className="flex items-center space-x-2">
                            <span className="material-symbols-outlined text-theme1">phone_in_talk</span>
                            <h6>Telefon</h6>
                        </div>
                        <div>+40 763 983 880</div>
                    </a>

                    <a
                        href="https://www.google.com/maps/place/Strada+Preciziei+11,+Bucure%C8%99ti+062202/@44.4287998,25.9813937,17z/data=!3m1!4b1!4m5!3m4!1s0x40b200ef8072bc97:0xc6855f9dd3149658!8m2!3d44.428796!4d25.983577"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block bg-bglight dark:bg-bgdark shadow px-4 py-2"
                    >
                        <div className="flex items-center space-x-2">
                            <span className="material-symbols-outlined text-theme1">map</span>
                            <h6>Locatie</h6>
                        </div>
                        <div>Strada Preciziei 11</div>
                    </a>

                    <div className="block bg-bglight dark:bg-bgdark shadow px-4 py-2">
                        <div className="flex items-center space-x-2">
                            <span className="material-symbols-outlined text-theme1">schedule</span>
                            <h6>Program</h6>
                        </div>
                        <div>08:00 - 17:00</div>
                    </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                    <form ref={form} onSubmit={sendEmail} className="flex flex-col space-y-4">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div className="form-group">
                                <label htmlFor="">Nume</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    onChange={updateInput}
                                    value={formData.firstName || ''}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Prenume</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    onChange={updateInput}
                                    value={formData.lastName || ''}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div className="form-group">
                                <label htmlFor="">Email</label>
                                <input type="email" name="email" onChange={updateInput} value={formData.email || ''} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Numar de telefon</label>
                                <input
                                    type="number"
                                    name="phoneNumber"
                                    onChange={updateInput}
                                    value={formData.phoneNumber || ''}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="">Subject</label>
                            <input type="text" name="subject" onChange={updateInput} value={formData.subject || ''} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="">Mesaj</label>
                            <textarea
                                name="message"
                                rows="5"
                                onChange={updateInput}
                                value={formData.message || ''}
                                className="resize-none"
                            ></textarea>
                        </div>

                        <button
                            className="theme-button1 flex justify-center items-center space-x-2 text-theme1 hover:text-white"
                            type="submmit"
                        >
                            <span>Trimite</span>
                            <span className="material-symbols-outlined">send</span>
                        </button>
                    </form>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2849.164888086609!2d25.981027315519146!3d44.42977980988461!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b200e590c24f47%3A0x7b148186b038e634!2sStrada%20Preciziei%2011%2C%20Bucure%C8%99ti!5e0!3m2!1sro!2sro!4v1641368641708!5m2!1sro!2sro"
                        allowFullScreen=""
                        title="location"
                        loading="lazy"
                        className="h-80 lg:h-full w-full"
                    />
                </div>
            </div>
        </div>
    );
}
