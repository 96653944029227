import React from 'react';
import * as Scroll from 'react-scroll';
import { ThemeContext } from '../utils/themeContext';
import LogoDark from '../images/logo-dark.png';
import LogoLight from '../images/logo-light.png';

export default function Footer() {
    const { theme, setTheme } = React.useContext(ThemeContext);
    let Link = Scroll.Link;
    return (
        <footer
            className="bg-bglight dark:bg-bgdark pt-8 pb-2 mt-16"
            style={{ boxShadow: '0 -2px 20px rgba(0,0,0,0.33' }}
        >
            <div className="container-padding flex flex-col items-center space-y-10">
                <img
                    src={theme === 'dark' ? LogoDark : LogoLight}
                    alt="PBT Prompt Service"
                    className="h-20 w-fit object-contain"
                />

                <div className="flex space-x-4">
                    <Link className="nav-link" to="servicii" spy={true} offset={-80} smooth>
                        Servicii
                    </Link>
                    <Link className="nav-link" to="galerie" spy={true} offset={-80} smooth>
                        Galerie
                    </Link>
                    <Link className="nav-link" to="opinii" spy={true} offset={-80} smooth>
                        Opinii
                    </Link>
                    <Link className="nav-link" to="contact" spy={true} offset={-80} smooth>
                        Contact
                    </Link>
                </div>

                <div className="space-y-4">
                    <a href="tel:+40763983880" className="nav-link flex items-center space-x-2">
                        <span className="material-symbols-outlined text-theme1">phone_in_talk</span>
                        <div>+40 763 983 880</div>
                    </a>

                    <a
                        href="https://www.google.com/maps/place/Strada+Preciziei+11,+Bucure%C8%99ti+062202/@44.4287998,25.9813937,17z/data=!3m1!4b1!4m5!3m4!1s0x40b200ef8072bc97:0xc6855f9dd3149658!8m2!3d44.428796!4d25.983577"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="nav-link flex items-center space-x-2"
                    >
                        <span className="material-symbols-outlined text-theme1">map</span>
                        <div>Strada Preciziei 11</div>
                    </a>

                    <Link to="contact" spy={true} offset={-80} smooth>
                        <button className="theme-button1 mt-10">Programeaza-te</button>
                    </Link>
                </div>

                <div className="opacity-50 text-gray-500">
                    &copy; PBT Prompt Service 2013 - {new Date().getFullYear()}
                </div>
            </div>
        </footer>
    );
}
