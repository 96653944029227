import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

const testimonialData = [
    {
        text: 'Recomand cu încredere! Experienta, profesionalism și punctualitate. Foarte mulțumit și tot respectul.',
        name: 'Constantin'
    },
    {
        text: 'Servicii ireproșabile și la prețuri foarte decente! Personal amabil si prompt! Recomand!',
        name: 'Radu'
    },
    {
        text: 'Foarte multumit de serviciile primite. Am avut parte de transparenta totala pe parcursul lucrarii iar masina a iesit peste asteptari. Recomand cu incredere :)',
        name: 'Sebastian'
    },
    {
        text: 'Treaba buna, raport pret-calitate bun.',
        name: 'Marius'
    },
    {
        text: 'Sunt la a 4-a mașina adusa aici. Rezultatul final este cel dorit. Mulțumesc!',
        name: 'Daniel'
    },
    {
        text: 'Oameni super profi. Am îndreptat și vopsit portiera dreapta la un Volvo și arată ca noua.',
        name: 'Florian'
    },
    {
        text: 'M-au ajutat sa repar masina dupa un incident in mai putin de o saptamana de cand i-am contactat. Comunicare eficienta si un rezultat excelent, recomand.',
        name: 'Mihai'
    },
    {
        text: 'Am venit la acest atelier pe baza review-urilor de aici, care sunt foarte corecte. Lucrarea a iesit perfect: atat reparatia cat si culoarea. Acum bara este deasemenea si imbinata frumos si corect. Multumesc si sper sa continuati tot asa :)',
        name: 'Rares'
    }
];

export default function Testimonials() {
    return (
        <div id="opinii">
            <div className="container-padding space-y-10">
                <h1>Părerile Clienților Despre Noi</h1>
                <Swiper
                    loop={true}
                    speed={500}
                    autoplay={{
                        delay: 7500,
                        disableOnInteraction: false
                    }}
                    slidesPerView={1}
                    spaceBetween={20}
                    breakpoints={{
                        768: {
                            slidesPerView: 3
                        }
                    }}
                    modules={[Autoplay]}
                >
                    {testimonialData.map((e, i) => (
                        <SwiperSlide
                            className="bg-bglight dark:bg-bgdark border-b border-b-theme1 space-y-10 shadow px-4 py-2"
                            key={i}
                        >
                            <p>{e.text}</p>
                            <h5 className="ml-auto">- {e.name}</h5>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}
