import React, { useState, useEffect } from 'react';
import * as Scroll from 'react-scroll';
import { ThemeContext } from '../utils/themeContext';
import LogoWhite from '../images/logo-white.png';
import LogoDark from '../images/logo-dark.png';
import LogoLight from '../images/logo-light.png';

export default function Navbar() {
    const { theme, setTheme } = React.useContext(ThemeContext);
    let Link = Scroll.Link;

    const [navbar, setNavbar] = useState(false);
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        toggle ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');

        const handleScroll = () => (window.scrollY >= 100 ? setNavbar(true) : setNavbar(false));

        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, [navbar, toggle]);

    return (
        <nav
            className={`fixed top-0 left-0 h-14 w-full duration-200 ease-in-out z-[999] ${
                navbar && 'bg-bglight dark:bg-bgdark shadow'
            }`}
        >
            <div className="container-padding relative flex items-center justify-between lg:justify-center h-full">
                <div className="hidden lg:flex absolute left-0 nav-links space-x-4">
                    <a href="tel:+40763983880" className="nav-link flex items-center space-x-2">
                        <span className="material-symbols-outlined text-theme1">phone_in_talk</span>
                    </a>
                    <a
                        href="https://www.google.com/maps/place/Strada+Preciziei+11,+Bucure%C8%99ti+062202/@44.4287998,25.9813937,17z/data=!3m1!4b1!4m5!3m4!1s0x40b200ef8072bc97:0xc6855f9dd3149658!8m2!3d44.428796!4d25.983577"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="nav-link flex items-center space-x-2"
                    >
                        <span className="material-symbols-outlined text-theme1">map</span>
                    </a>
                </div>

                <div className="hidden lg:flex space-x-8 uppercase">
                    <Link
                        to="servicii"
                        spy={true}
                        offset={-80}
                        smooth
                        className="nav-link"
                        onClick={() => setToggle(false)}
                    >
                        Servicii
                    </Link>
                    <Link
                        to="galerie"
                        spy={true}
                        offset={-80}
                        smooth
                        className="nav-link"
                        onClick={() => setToggle(false)}
                    >
                        Galerie
                    </Link>
                    <div className="px-24" />
                    <Link
                        to="opinii"
                        spy={true}
                        offset={-80}
                        smooth
                        className="nav-link"
                        onClick={() => setToggle(false)}
                    >
                        Opinii
                    </Link>
                    <Link
                        to="contact"
                        spy={true}
                        offset={-80}
                        smooth
                        className="nav-link"
                        onClick={() => setToggle(false)}
                    >
                        Contact
                    </Link>
                </div>

                <Link to="landing" spy={true} smooth>
                    <div className="hidden lg:block">
                        <img
                            src={!navbar ? LogoWhite : theme === 'dark' ? LogoDark : LogoLight}
                            alt="PBT Prompt Service"
                            className="h-10 w-fit object-contain lg:absolute lg:top-[50%] lg:left-[50%] lg:-translate-x-[50%] lg:-translate-y-[50%] cursor-pointer"
                        />
                    </div>
                    <div className="lg:hidden">
                        <img
                            src={theme === 'dark' ? LogoDark : LogoLight}
                            alt="PBT Prompt Service"
                            className="h-10 w-fit object-contain lg:absolute lg:top-[50%] lg:left-[50%] lg:-translate-x-[50%] lg:-translate-y-[50%] cursor-pointer"
                        />
                    </div>
                </Link>

                <div className="hidden lg:flex items-center absolute right-0 space-x-8">
                    <Link
                        to="contact"
                        spy={true}
                        offset={-80}
                        smooth
                        className="cta-button"
                        onClick={() => setToggle(false)}
                    >
                        <button className="theme-button1">Programeaza-te</button>
                    </Link>
                    <div
                        className="text-theme1 cursor-pointer"
                        onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                    >
                        {theme === 'dark' ? (
                            <span className="block material-symbols-outlined">light_mode</span>
                        ) : (
                            <span className="block material-symbols-outlined">dark_mode</span>
                        )}
                    </div>
                </div>

                <span className="material-symbols-outlined cursor-pointer lg:hidden" onClick={() => setToggle(!toggle)}>
                    menu
                </span>

                <div
                    className={`
                            absolute flex lg:hidden flex-col top-0 p-6 space-y-10 bg-[#eee] dark:bg-[#333] h-screen duration-300 ease-in-out
                            ${toggle ? 'right-0 overflow-y-scroll scrollbar-none' : '-right-[100%]'}
                           
                    `}
                >
                    <span
                        className="absolute top-4 right-8 lg:hidden material-symbols-outlined ml-auto cursor-pointer"
                        onClick={() => setToggle(false)}
                    >
                        close
                    </span>

                    <Link
                        to="servicii"
                        spy={true}
                        offset={-80}
                        smooth
                        className="nav-link"
                        onClick={() => setToggle(false)}
                    >
                        Servicii
                    </Link>
                    <Link
                        to="galerie"
                        spy={true}
                        offset={-80}
                        smooth
                        className="nav-link"
                        onClick={() => setToggle(false)}
                    >
                        Galerie
                    </Link>
                    <Link
                        to="opinii"
                        spy={true}
                        offset={-80}
                        smooth
                        className="nav-link"
                        onClick={() => setToggle(false)}
                    >
                        Opinii
                    </Link>
                    <Link
                        to="contact"
                        spy={true}
                        offset={-80}
                        smooth
                        className="nav-link"
                        onClick={() => setToggle(false)}
                    >
                        Contact
                    </Link>

                    <a
                        href="tel:+40763983880"
                        className="nav-link flex items-center space-x-2"
                        onClick={() => setToggle(false)}
                    >
                        <span className="material-symbols-outlined text-theme1">phone_in_talk</span>
                        <div>+40 763 983 880</div>
                    </a>

                    <a
                        href="https://www.google.com/maps/place/Strada+Preciziei+11,+Bucure%C8%99ti+062202/@44.4287998,25.9813937,17z/data=!3m1!4b1!4m5!3m4!1s0x40b200ef8072bc97:0xc6855f9dd3149658!8m2!3d44.428796!4d25.983577"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="nav-link flex items-center space-x-2"
                        onClick={() => setToggle(false)}
                    >
                        <span className="material-symbols-outlined text-theme1">map</span>
                        <div>Strada Preciziei 11</div>
                    </a>

                    <div className="nav-link flex items-center space-x-2" onClick={() => setToggle(false)}>
                        <span className="material-symbols-outlined">schedule</span>
                        <div className="text-xs">
                            <div>Luni - Vineri</div>
                            <div>08:00 - 17:00</div>
                        </div>
                    </div>

                    <Link to="contact" spy={true} offset={-80} smooth>
                        <button className="theme-button1 mt-10" onClick={() => setToggle(false)}>
                            Programeaza-te
                        </button>
                    </Link>

                    <div className="flex w-fit mx-auto text-theme1 cursor-pointer">
                        <div onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}>
                            {theme === 'dark' ? (
                                <span className="material-symbols-outlined">light_mode</span>
                            ) : (
                                <span className="material-symbols-outlined">dark_mode</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}
