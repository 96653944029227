import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import {
    Navbar,
    BackToTop,
    Whatsapp,
    Landing,
    Suppliers,
    Services,
    Showcase,
    Testimonials,
    Contact,
    Footer
} from './components';

export default function App() {
    useEffect(() => {
        ReactGA.initialize('G-C43WTM5XYS', { debug: false });
        ReactGA.send('pageview');
    }, [window.location.pathname, window.location.search]);

    return (
        <div>
            <Navbar />
            <BackToTop />
            <Whatsapp />
            <Landing />
            <div className="my-16 lg:my-0" />
            <Suppliers />
            <div className="py-10" />
            <Services />
            <div className="section-spacing" />
            <Showcase />
            <div className="section-spacing" />
            <Testimonials />
            <div className="section-spacing" />
            <Contact />
            <Footer />
        </div>
    );
}
