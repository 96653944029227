import React from 'react';
import * as Scroll from 'react-scroll';

import LandingIMG from '../images/landing.png';

export default function Landing() {
    let Link = Scroll.Link;

    return (
        <div className="relative flex items-center pt-[120px] lg:pt-[200px]" id="landing">
            <div className="absolute top-0 left-[50%] -translate-x-[50%]  h-[100vh] w-28 bg-gradient-to-b from-theme1 to-transparent" />
            <div className="container-padding z-10">
                <div className="grid grid-cols-1 lg:grid-cols-12 gap-10 lg:gap-0">
                    <div className="col-span-1 lg:col-span-6 space-y-10 order-last lg:order-first">
                        <h1>Servicii profesionale</h1>
                        <p className="lg:w-3/4">
                            Inspectăm cu atenție mașina ta și reparăm sau înlocuim toate sistemele defecte la cele mai
                            bune prețuri.
                        </p>
                        <div className="flex space-x-2">
                            <Link to="contact" spy={true} offset={-80} smooth>
                                <button className="theme-button1 bg-theme1 text-white">Programeaza-te</button>
                            </Link>
                            <Link to="servicii" spy={true} offset={-80} smooth>
                                <button className="theme-button1">Servicii</button>
                            </Link>
                        </div>
                    </div>
                    <div className="col-span-1 lg:col-span-6">
                        <img src={LandingIMG} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}
