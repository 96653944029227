import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from './utils/themeContext';
import App from './App';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'lightgallery.js/dist/css/lightgallery.css';
import 'react-toastify/dist/ReactToastify.css';
import './tailwind.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider>
            <main
                className="bg-[#fafafa] dark:bg-[#151515] text-black dark:text-white transition-all min-h-screen"
                id="main"
            >
                <App />
            </main>
        </ThemeProvider>
    </React.StrictMode>
);
