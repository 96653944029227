import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import Castrol from '../images/suppliers/castrol.png';
import Continental from '../images/suppliers/continental.png';
import Dunlop from '../images/suppliers/dunlop.png';
import Goodyear from '../images/suppliers/goodyear.png';
import Michelin from '../images/suppliers/michelin.png';
import Mobil1 from '../images/suppliers/mobil1.png';
import Pirelli from '../images/suppliers/pirelli.png';
import Total from '../images/suppliers/total.png';
import Valvoline from '../images/suppliers/valvoline.png';

const suppliersData = [
    { src: Castrol, name: 'Castrol' },
    { src: Continental, name: 'Continental' },
    { src: Dunlop, name: 'Dunlop' },
    { src: Goodyear, name: 'Goodyear' },
    { src: Michelin, name: 'Michelin' },
    { src: Mobil1, name: 'Mobil1' },
    { src: Pirelli, name: 'Pirelli' },
    { src: Total, name: 'Total' },
    { src: Valvoline, name: 'Valvoline' }
];

export default function Suppliers() {
    return (
        <Swiper
            loop={true}
            speed={8000}
            autoplay={{
                delay: 0,
                disableOnInteraction: false
            }}
            slidesPerView={2}
            spaceBetween={10}
            breakpoints={{
                1024: {
                    slidesPerView: 5
                }
            }}
            modules={[Autoplay]}
            className="suppliers-swiper pointer-events-none"
        >
            {suppliersData.map((e, i) => (
                <SwiperSlide className="flex justify-center items-center" key={i}>
                    <img src={e.src} alt={e.name} className="h-6 w-fit object-contain" />
                </SwiperSlide>
            ))}
        </Swiper>
    );
}
