import React from 'react';
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';

import IMG1 from '../images/gallery/1.jpeg';
import IMG2 from '../images/gallery/2.jpeg';
import IMG3 from '../images/gallery/3.jpeg';
import IMG4 from '../images/gallery/4.jpeg';
import IMG5 from '../images/gallery/5.jpeg';
import IMG6 from '../images/gallery/6.jpeg';
import IMG7 from '../images/gallery/7.jpeg';
import IMG8 from '../images/gallery/8.jpeg';
import IMG9 from '../images/gallery/9.jpeg';
import IMG10 from '../images/gallery/10.jpeg';

const galleryItems = [IMG1, IMG2, IMG3, IMG4, IMG5, IMG6, IMG7, IMG8, IMG9, IMG10];

export default function Showcase() {
    return (
        <div id="galerie">
            <div className="container-padding space-y-10">
                <h1>Galerie</h1>

                <LightgalleryProvider
                    onAfterOpen={() => document.body.classList.add('no-scroll')}
                    onCloseAfter={() => document.body.classList.remove('no-scroll')}
                    lightgallerySettings={{
                        download: false,
                        speed: 300
                    }}
                >
                    <div className="relative grid grid-cols-2 lg:grid-cols-5 gap-2">
                        {galleryItems.map((e, i) => (
                            <LightgalleryItem src={e} thumb={e} key={i} itemClassName="gallery-item">
                                <img src={e} alt={e} className="h-80 w-full object-cover cursor-pointer" />
                            </LightgalleryItem>
                        ))}
                    </div>
                </LightgalleryProvider>
            </div>
        </div>
    );
}
