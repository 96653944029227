import React from 'react';

import OilChange from '../images/services/oil-change.jpg';
import Paint from '../images/services/spray-paint.jpg';
import Tinware from '../images/services/tinware.jpg';
import FilterChange from '../images/services/filter-change.jpg';
import TireChange1 from '../images/services/tire-change1.jpg';
import TireChange2 from '../images/services/tire-change2.jpg';
import TireBalance from '../images/services/tire-balance.jpg';
import TirePressure from '../images/services/tire-pressure.jpg';
import TirePatch from '../images/services/tire-patch.jpg';
import RimClean from '../images/services/rim-clean.jpg';
import RimStraighten from '../images/services/rim-straight.jpg';
import TireChain from '../images/services/tire-chain.jpeg';
import TireValve from '../images/services/tire-valve.jpg';

const servicesData = [
    { src: OilChange, name: 'Schimb de Ulei' },
    { src: Paint, name: 'Vopsire' },
    { src: Tinware, name: 'Tinichigerie' },
    { src: FilterChange, name: 'Schimb de Filtre' },
    { src: TireChange1, name: '(De)montare Roata' },
    { src: TireChange2, name: '(De)montare Anvelopa' },
    { src: TireBalance, name: 'Echilibrat Roata de Pbumb' },
    { src: TirePressure, name: 'Presiune Pneuri' },
    { src: TireValve, name: 'Valva + Montaj' },
    { src: TirePatch, name: 'Petec Camera la Rece/Cald' },
    { src: RimClean, name: 'Curatat Janta' },
    { src: RimStraighten, name: 'Indreptat Janta de Tabla' },
    { src: TireChain, name: 'Snur Anvelopa Auto/Camion' },
    { src: TireValve, name: 'Reglaj Capace Camera' }
];

export default function Services() {
    return (
        <div id="servicii">
            <div className="container-padding space-y-10">
                <div>
                    <h1>Servicii</h1>
                    <h6 className="text-gray-500">
                        Valabile pentru autoturisme(2x4, 4x4), moto, comerciale, stivuitor
                    </h6>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
                    {servicesData.map((e, i) => (
                        <div className="relative text-white overflow-hidden" key={i}>
                            <img
                                src={e.src}
                                alt={e.name}
                                className="h-72 w-full object-cover hover:scale-[1.15] duration-200 ease-in-out"
                            />
                            <h6 className="absolute top-0 left-0 bg-gradient-to-t from-black via-transparent to-transparent h-full w-full p-1 flex items-end text-xl pointer-events-none">
                                {e.name}
                            </h6>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
